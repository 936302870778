import { useCallback } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync } from 'state/actions'
import { airdrop } from 'utils/callHelpers'
import { useAirdropContract } from './useContract'


const useAirdrop = () => {
    const dispatch = useDispatch()
    const { account } = useWallet()

    const airdropContract = useAirdropContract();

    const handleClaim = useCallback(async () => {
      const txHash = await airdrop(airdropContract, account)
      dispatch(fetchFarmUserDataAsync(account))
      return txHash
    }, [account, dispatch, airdropContract])

    return { onClaim: handleClaim }
}

export default useAirdrop;