import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Text } from '@pancakeswap-libs/uikit'

interface ChainErrorModalProps {
  onDismiss?: () => void
}

const ModalContent = styled.div`
  margin-bottom: 16px;
`

const ChainErrorModal: React.FC<ChainErrorModalProps> = ({ onDismiss }) => {
  return (
    <Modal title="Connection Error" onDismiss={onDismiss}>
      <ModalContent>
        <Text>Kindly switch to KAVA Network.</Text>
      </ModalContent>
    </Modal>
  )
}

export default ChainErrorModal
