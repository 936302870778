import React from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Button, useModal } from '@pancakeswap-libs/uikit'
import ClaimModal from './ClaimModal'
import TokenBalance from './TokenBalance'

const ClaimToken: React.FC = () =>{
    const { account } = useWallet()
    const [onClaim] = useModal(<ClaimModal/>)

    return (
        // account ? <Button size="sm" mr="12px" onClick={onClaim}>Claim / PAROLE: <TokenBalance/> </Button> : <></>
        <></>
    )
}

export default ClaimToken