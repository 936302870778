import { PoolCategory, PoolConfig, QuoteToken } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   tokenName: '',
  //   stakingTokenName: QuoteToken.PAYROLE,
  //   stakingTokenAddress: {
  //     137: '',
  //     97: '',
  //     56: '',
  //   },
  //   contractAddress: {
  //     137: '',
  //     97: '',
  //     56: '',
  //   },
  //   rewardTokenAddress: {
  //     137: '',
  //     97: '',
  //     56: '',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: '',
  //   harvest: true,
  //   tokenPerBlock: '0.002662037037037',
  //   sortOrder: 2,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   burnFee: 100,
  //   weeklyroi: 'Starting Saturday 13:30 UTC'
  // },
]

export default pools
